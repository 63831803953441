import { BrowserRouter, Routes, Route } from "react-router-dom";


//Pages
import Home from "./pages/Home";
import About from "./pages/About";
import Facilities from "./pages/Facilities";
import Gallery from "./pages/Gallery";
import Teacher from "./pages/Teacher";
import Contact from "./pages/Contact";
import Enroll from "./pages/Enroll";
import Admin from "./pages/Admin";
import ImageUploader from "./pages/ImageUploader";
import Galleries from "./pages/Galleries";


//Components
import Address from "./components/Address";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import MobileNavbar from "./components/MobileNavbar";
import Photos from "./pages/Photos";


function App() {

  let isMobile = window.matchMedia("(max-width: 480px)").matches;

  return (
    <div className="App">
      <Address />
      <BrowserRouter>
        {isMobile ? <MobileNavbar /> : <Navbar />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="facilities" element={<Facilities />} />
          <Route path="gallery" element={<Gallery/>} />
          <Route path="gallery/:galleryId" element={<Photos  />} />
          <Route path="teacher" element={<Teacher />} />
          <Route path="contact" element={<Contact />} />
          <Route path="enroll" element={<Enroll />} />
          <Route path="data/admin/beingrahuul/form" element={<Admin />} />
          <Route path="admin/image-upload/:id" element={<ImageUploader />} />
          <Route path="admin/galleries" element={<Galleries />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
