import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import icons

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 40px 0;
`;

const GalleryName = styled.h1`
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 30px;
  width: 100%;
  max-width: 1200px;
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 8px;
  object-fit: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  svg {
    font-size: 20px;
  }
`;

const PageInfo = styled.span`
  margin: 0 10px;
  font-size: 16px;
`;


function Photos() {
  const { galleryId } = useParams();
  const [images, setImages] = useState([]);
  const [galleryName, setGalleryName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const imagesPerPage = 20;
  
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`https://bl-production.up.railway.app/api/gallery/${galleryId}`);
        const { imagesUrl, name } = response.data;
        setGalleryName(name);
        setImages(imagesUrl.reverse()); // Reverse the array to show the last photo first
        setTotalPages(Math.ceil(imagesUrl.length / imagesPerPage));
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };
  
    fetchImages();
  }, [galleryId]);
  

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };



  // Calculate the images to display for the current page
  const startIndex = (currentPage - 1) * imagesPerPage;
  const currentImages = images.slice(startIndex, startIndex + imagesPerPage);

  return (
    <Container>
      <GalleryName>{galleryName}</GalleryName>
      <ImageGrid>
        {currentImages.map((url, index) => (
          <Image key={index} src={url} alt={`Image ${index + 1}`}  />
        ))}
      </ImageGrid>
      <Pagination>
        <Button onClick={handlePrevPage} disabled={currentPage === 1}>
          <FaChevronLeft />
        </Button>
        <PageInfo>Page {currentPage} of {totalPages}</PageInfo>
        <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
          <FaChevronRight />
        </Button>
      </Pagination>
    </Container>
  );
}

export default Photos;
